import React from "react";
import { Link } from "react-router-dom";
// import FastMail from "../../Images/fast-mail.png";

export default function main() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div>
      <div className="container mt-5 mb-5 pt-5">
        <div className="row">
          <div className="col-md-12 text-center">
            <h1 className="whyus-head-text">Connect with Us</h1>
            <p className="whyus-subhead-text">
              Unlock New Opportunities with Bharat Soft Suppliers
            </p>
            <p className="mt-3 whyus-subhead-text">
              Receive work offers from top-notch software suppliers by
              connecting with us. Share your skills and expertise, and let us
              match you with projects that align with your professional goals.
            </p>
          </div>
        </div>
        <div className="row mt-5 d-flex justify-content-center">
          <div className="col-md-3">
            <div className="row">
              <img src="" alt="" />
            </div>
            <div className="row text-center">
              <h3>Get Matched with the Best Projects:</h3>
              <p>
                We connect you with opportunities that suit your skills and
                interests.
              </p>
            </div>
          </div>
          <div className="col-md-1"></div>
          <div className="col-md-3">
            <div className="row">
              <img src="" alt="" />
            </div>
            <div className="row text-center">
              <h3>Expand Your Professional Network</h3>
              <p>
                Build relationships with leading software suppliers and industry
                experts.
              </p>
            </div>
          </div>
          <div className="col-md-1"></div>
          <div className="col-md-3">
            <div className="row">
              <img src="" alt="" />
            </div>
            <div className="row text-center">
              <h3>Grow Your Career</h3>
              <p>
                Gain access to a variety of projects that help you enhance your
                skills and advance your career.
              </p>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-12 text-center">
            <Link
              to="/company-register"
              className="btn btn-lg whyus-register-btn"
              onClick={scrollToTop}
            >
              Join Now and Start Receiving Offers
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
