import React from "react";

export default function footer() {
  return (
    <div>
      <footer className="text-center text-lg-start bg-body-tertiary text-muted">
        <section className="">
          <div className="container text-center text-md-start mt-5 pt-4">
            <div className="row mt-3">
              <div className="col-md-5 col-lg-6 col-xl-3 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">
                  <h3>Bharat Soft Suppliers</h3>
                </h6>
                {/* <p>
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Deserunt, necessitatibus!
                </p> */}
                <div>
                  <a href="/" className="me-4 text-reset">
                    <i className="fab fa-facebook-f fa-xl"></i>
                  </a>
                  <a href="/" className="me-4 text-reset">
                    <i className="fab fa-twitter fa-xl"></i>
                  </a>
                  <a href="/" className="me-4 text-reset">
                    <i className="fab fa-google fa-xl"></i>
                  </a>
                  <a href="/" className="me-4 text-reset">
                    <i className="fab fa-instagram fa-xl"></i>
                  </a>
                  <a href="/" className="me-4 text-reset">
                    <i className="fab fa-linkedin fa-xl"></i>
                  </a>
                  {/* <a href="/" className="me-4 text-reset">
                    <i className="fab fa-github fa-xl"></i>
                  </a> */}
                </div>
              </div>

              {/* <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Products</h6>
                <p>
                  <a href="#!" className="text-reset">
                    Angular
                  </a>
                </p>
                <p>
                  <a href="#!" className="text-reset">
                    React
                  </a>
                </p>
                <p>
                  <a href="#!" className="text-reset">
                    Vue
                  </a>
                </p>
                <p>
                  <a href="#!" className="text-reset">
                    Laravel
                  </a>
                </p>
              </div> */}

              <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Useful links</h6>
                <p>
                  <a href="/" className="text-reset">
                    Home
                  </a>
                </p>
                <p>
                  <a href="/" className="text-reset">
                    Company Map
                  </a>
                </p>
                <p>
                  <a href="/" className="text-reset">
                    Company Registration
                  </a>
                </p>
                {/* <p>
                  <a href="/" className="text-reset">
                    Blogs
                  </a>
                </p> */}
                <p>
                  <a href="/" className="text-reset">
                    About Us
                  </a>
                </p>
              </div>

              <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                <h6 className="text-uppercase fw-bold mb-4">
                  Address & Contact
                </h6>
                <p>
                  <i className="fas fa-home me-3"></i> Klifrakplantsoen 178,
                  3544 RC Utrecht. The Netherlands
                </p>
                <p>
                  <i className="fas fa-envelope"></i>{" "}
                  hello@bellegirllifestyle.com
                </p>
                <p>
                  <i className="fas fa-phone me-3"></i> Payal Gandhi: +31
                  644806659
                </p>
                <p>
                  <i className="fas fa-phone me-3"></i> Team Coordinator: +31
                  644905433
                </p>
              </div>
            </div>
          </div>
        </section>

        <div className="text-center p-4">© 2024 Bharat Soft Suppliers</div>
      </footer>
    </div>
  );
}
