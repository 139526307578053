import React from "react";

export default function Main() {
  return (
    <>
      <div className="container-fluid add-your-company-main-container mt-3 shadow-sm mb-5 pb-3">
        <div className="container">
          <div className="row text-center pt-4">
            <div className="col-md-12">
              <h1 className="add-company-head">Add Your Company</h1>
              <p className="add-company-sub-head">
                Partner with Bharat Soft Suppliers to Connect with Top Software
                Clients Globally
              </p>
              <p className="add-company-sub-head">
                Expand your business reach and connect with high-profile clients
                by adding your company to our network. Benefit from global
                exposure and exciting new opportunities.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="row mt-3 d-flex justify-content-center">
              <div className="col-md-3">
                <div className="row">
                  <img src="" alt="" />
                </div>
                <div className="row text-center">
                  <h3>Access High-Quality Clients</h3>
                  <p>
                    Connect with leading businesses seeking reliable software
                    suppliers.
                  </p>
                </div>
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-3">
                <div className="row">
                  <img src="" alt="" />
                </div>
                <div className="row text-center">
                  <h3>Expand Your Market Reach</h3>
                  <p>
                    Gain visibility in new markets and grow your customer base
                    worldwide.
                  </p>
                </div>
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-3">
                <div className="row">
                  <img src="" alt="" />
                </div>
                <div className="row text-center">
                  <h3>Enhance Business Opportunities</h3>
                  <p>
                    Partner with us to unlock a steady stream of project offers
                    tailored to your expertise.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
