import React from 'react'
import { useParams } from 'react-router-dom';
import info from './info.json'
function Details() {

    const { id } = useParams();

    const data=info[id-1];

    console.log(`./companyImages/${data.contactdetails.image}`)
    const styles = {
        container: {
          fontFamily: 'Arial, sans-serif',
          border: '1px solid #ddd',
          borderRadius: '12px',
          padding: '20px',
          maxWidth: '900px',
          margin: 'auto',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          backgroundColor: '#f9f9f9',
        },
        header: {
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          marginBottom: '20px',
        },
        logo: {
          borderRadius: '50%',
          width: '120px',
          height: '120px',
          marginRight: '20px',
        },
        titleSection: {
          flex: 1,
          minWidth: '200px',
        },
        title: {
          fontSize: '28px',
          margin: '0',
          color: '#333',
        },
        tagline: {
          fontSize: '18px',
          color: '#888',
          marginBottom: '10px',
        },
        infoSection: {
          marginBottom: '20px',
        },
        infoTitle: {
          fontSize: '20px',
          margin: '0 0 10px 0',
          color: '#333',
          borderBottom: '2px solid #007bff',
          paddingBottom: '5px',
        },
        infoText: {
          margin: '0 0 10px 0',
          lineHeight: '1.8',
          color: '#555',
        },
        list: {
          padding: '0',
          margin: '0',
          listStyleType: 'none',
          display: 'flex',
          flexWrap: 'wrap',
          gap: '10px',
        },
        listItem: {
          padding: '10px 15px',
          backgroundColor: '#007bff',
          color: '#fff',
          borderRadius: '8px',
          fontSize: '14px',
          fontWeight: 'bold',
        },
        imageGallery: {
          display: 'flex',
          flexWrap: 'wrap',
          gap: '10px',
          marginBottom: '20px',
        },
        galleryImage: {
          width: 'calc(33% - 10px)',
          borderRadius: '8px',
          objectFit: 'cover',
        },
        contact: {
          display: 'flex',
          alignItems: 'center',
          marginTop: '20px',
          padding: '10px',
          border: '1px solid #ddd',
          borderRadius: '8px',
          backgroundColor: '#fff',
        },
        contactImage: {
          borderRadius: '50%',
          width: '60px',
          height: '60px',
          marginRight: '15px',
        },
        contactName: {
          fontSize: '18px',
          margin: '0',
          fontWeight: 'bold',
          color: '#333',
        },
        contactPhone: {
          fontSize: '16px',
          color: '#666',
          margin: '0',
        },
        link: {
          color: '#007bff',
          textDecoration: 'none',
          fontSize: '16px',
          fontWeight: 'bold',
          display: 'inline-block',
          marginTop: '10px',
        }
      };
    
      return (
        <div style={styles.container}>
          <div style={styles.header}>
            <img src={`./companyImages/img${data.id}.jpg`} alt="Company Logo" style={styles.logo} />
            <div style={styles.titleSection}>
              <h1 style={styles.title}>{data.name}</h1>
              <p style={styles.tagline}>{data.tagline}</p>
            </div>
          </div>
    
          <div style={styles.infoSection}>
            <h2 style={styles.infoTitle}>Company Overview</h2>
            <p style={styles.infoText}>{data.description}</p>
            <p style={styles.infoText}><strong>Location:</strong> {data.location}</p>
            <p style={styles.infoText}><strong>Average Project Size:</strong> {data.averageProjectSize}</p>
            <p style={styles.infoText}><strong>Projects Completed:</strong> {data.projectCompleted}</p>
            <p style={styles.infoText}><strong>Company Size:</strong> {data.companySize}</p>
            <p style={styles.infoText}><strong>Remote Collaboration Possible:</strong> {data.remoteCollabrationPossible}</p>
            <p style={styles.infoText}><strong>Company Age:</strong> {data.companyAge}</p>
            <h2 style={styles.infoTitle}>Development process</h2>
            <p style={styles.infoText}>{data.developmentProcess}</p>
          </div>
          
          <div style={styles.infoSection}>
            <h2 style={styles.infoTitle}>Ideal Clients</h2>
            <p style={styles.infoText}>{data.idealClients}</p>
          </div>
    
          <div style={styles.infoSection}>
            <h2 style={styles.infoTitle}>Methodologies</h2>
            <ul style={styles.list}>
              {data.methologies.map((method, index) => (
                <li key={index} style={styles.listItem}>{method}</li>
              ))}
            </ul>
          </div>
    
          <div style={styles.infoSection}>
            <h2 style={styles.infoTitle}>Languages</h2>
            <ul style={styles.list}>
              {data.languages.map((language, index) => (
                <li key={index} style={styles.listItem}>{language}</li>
              ))}
            </ul>
          </div>
    
          <div style={styles.infoSection}>
            <h2 style={styles.infoTitle}>Types of Projects</h2>
            <ul style={styles.list}>
              {data.typeOfProjects.map((project, index) => (
                <li key={index} style={styles.listItem}>{project}</li>
              ))}
            </ul>
          </div>
    
          <div style={styles.infoSection}>
            <h2 style={styles.infoTitle}>Image Gallery</h2>
            <div style={styles.imageGallery}>
              {data.images.map((image, index) => (
                <img key={index} src={`./companyImages/${image}`} alt={`Ftechiz ${index + 1}`} style={styles.galleryImage} />
              ))}
            </div>
          </div>
    
          <a href={data.link} style={styles.link} target="_blank" rel="noopener noreferrer">
            Visit Website
          </a>
    
          <div style={styles.contact}>
            <img src={`./companyImages/${data.contactdetails.image}`} alt="Contact Person" style={styles.contactImage} />
            <div>
              <p style={styles.contactName}>{data.contactdetails.name}</p>
              {data.contactdetails.email?<p style={styles.contactName}>{data.contactdetails.email}</p>:""}
              <p style={styles.contactPhone}>{data.contactdetails.phoneno}</p>
            </div>
          </div>
        </div>
      );
}

export default Details