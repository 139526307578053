import React from "react";

export default function main() {
  return (
    <>
      <div>
        <div className="container mt-5 mb-5 about-main-container">
          <h2 className="text-center">
            About Our <span className="aboutus-span">Company</span>
          </h2>
          <p className="mt-3">
            Every day, creative minds in the entrepreneurial and organizational
            spheres conceive groundbreaking ideas for digital products, with the
            goal of tackling specific challenges or capitalizing on emerging
            market opportunities. Bharat Soft Supplier is committed to aiding
            these forward-thinking companies. Bharat Soft Supplier facilitates
            the realization of their digital product concepts by linking them
            with the most suitable software suppliers. This matchmaking process
            occurs seamlessly through both our online platform and offline
            matchmaking services. Our online platform equips users with diverse
            search functions and a supplier map, empowering them to pinpoint the
            optimal software supplier for their requirements. Furthermore,
            software clients can share their projects with us, allowing us to
            efficiently pair them with the fitting software supplier. Moreover,
            our global team of account executives is poised to offer support to
            software suppliers and clients across the world. Whether companies
            are in pursuit of new customers or on the quest for the perfect
            supplier, they can take advantage of the benefits offered by our
            premium services.
          </p>
          <h2 className="text-center mt-3 mb-3">
            <span className="aboutus-span pt-5">Contact</span> Us
          </h2>
          <div>
            <div className="row">
              <div className="col-md-6 about-page-contact-1">
                <p className="text-center">
                  <i className="fa fa-phone"></i> Payal Gandhi: +31 644806659
                  <br />
                  <i className="fa fa-phone"></i> Team Coordinator: +31
                  644905433
                  <br />
                  <i className="fa-regular fa-envelope"></i>{" "}
                  hello@bellegirllifestyle.com
                  <br />
                  <i className="fa-solid fa-location-dot"></i> Klifrakplantsoen
                  178, 3544 RC Utrecht. The Netherlands
                </p>
              </div>
              <div className="col-md-6 about-page-contact-2">
                <p className="text-center">
                  KVK Number (COC): 80193935 <br />
                  VAT Number: NL003405066B77 <br />
                  A/c No (IBAN): BE90 9671 8679 9532 <br />
                  Account Holder: Bellegirl Lifestyle <br />
                  BIC: TRWIBEB1XXX
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
