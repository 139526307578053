import React, { useRef } from "react";
import Logo from "../../Images/navLogo.png";
import { Link } from "react-router-dom";

export default function Navbar() {
  const navbarToggler = useRef();

  const scrollToTop = () => {
    navbarToggler.current.click();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <nav className="navbar navbar-expand-lg shadow-sm sticky-top bg-white">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img
              src={Logo}
              alt="Logo"
              width="240"
              height="80"
              className="d-inline-block align-text-top"
              onClick={scrollToTop}
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            ref={navbarToggler}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 d-flex"></ul>
            <div className="d-flex">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0 d-flex">
                <li className="nav-item m-3" onClick={scrollToTop}>
                  <Link
                    className="nav-link nav-list-options"
                    aria-current="page"
                    to="/"
                  >
                    Home
                  </Link>
                </li>
                <li className="nav-item m-3" onClick={scrollToTop}>
                  <Link className="nav-link nav-list-options" to="/company-map">
                    Company Map
                  </Link>
                </li>
                <li className="nav-item m-3" onClick={scrollToTop}>
                  <Link
                    className="nav-link nav-list-options"
                    to="/company-register"
                  >
                    Company Registration
                  </Link>
                </li>
                {/* <li className="nav-item m-3" onClick={scrollToTop}>
                  <Link className="nav-link nav-list-options" to="/blogs">
                    Blogs
                  </Link>
                </li> */}
                <li className="nav-item m-3" onClick={scrollToTop}>
                  <Link className="nav-link nav-list-options" to="/about">
                    About us
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
