import React from "react";
import Img1 from "./companyImages/img1.jpg";
import Img2 from "./companyImages/img2.jpg";
import Img3 from "./companyImages/img3.jpg";
import Img4 from "./companyImages/img4.jpeg";
import Img5 from "./companyImages/img5.jpg";
import Img6 from "./companyImages/img6.png";
import Img7 from "./companyImages/img7.jpg";
import Img8 from "./companyImages/img8.png";
import Img9 from "./companyImages/img9.jpg";
import Img10 from "./companyImages/img10.jpg";
import Img11 from "./companyImages/img11.png";
import Img12 from "./companyImages/img12.png";
import Img13 from "./companyImages/img13.png";
import Img14 from "./companyImages/img14.png";
import Img15 from "./companyImages/img15.jpg";
import Img16 from "./companyImages/img16.jpg";
import Img17 from "./companyImages/img17.jpg";
import Img18 from "./companyImages/img18.png";
import info from './info.json'
import ListCard from "./ListCard.js";

export default function main() {
  
  return (
    <div>
      <div className="container">
        <div className="row mt-3 mb-3 text-center">
          <div className="col-md-12">
            <h1 className="ProjectForm_head" style={{ color: "#06038D" }}>
              Company Map
            </h1>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-12">
            <div class="row row-cols-1 row-cols-md-3 g-4">
              <ListCard
                Img={Img1}
                project_size="N/A"
                projects_done="100-499"
                company_size="Small"
                company_website_link="https://www.google.com"
                company_details_link="https://www.gagan257.tech"
                information={info[0]}
              />
              <ListCard
                Img={Img2}
                project_size="N/A"
                projects_done="100-499"
                company_size="Small"
                company_website_link="https://www.google.com"
                company_details_link="https://www.gagan257.tech"
                information={info[1]}
              />
              <ListCard
                Img={Img3}
                project_size="N/A"
                projects_done="100-499"
                company_size="Small"
                company_website_link="https://www.google.com"
                company_details_link="https://www.gagan257.tech"
                information={info[2]}
              />
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-12">
            <div class="row row-cols-1 row-cols-md-3 g-4">
              <ListCard
                Img={Img4}
                project_size="N/A"
                projects_done="100-499"
                company_size="Small"
                company_website_link="https://www.google.com"
                company_details_link="https://www.gagan257.tech"
                information={info[3]}
              />
              <ListCard
                Img={Img5}
                project_size="N/A"
                projects_done="100-499"
                company_size="Small"
                company_website_link="https://www.google.com"
                company_details_link="https://www.gagan257.tech"
                information={info[4]}
              />
              <ListCard
                Img={Img6}
                project_size="N/A"
                projects_done="100-499"
                company_size="Small"
                company_website_link="https://www.google.com"
                company_details_link="https://www.gagan257.tech"
                information={info[5]}
              />
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-12">
            <div class="row row-cols-1 row-cols-md-3 g-4">
              <ListCard
                Img={Img7}
                project_size="N/A"
                projects_done="100-499"
                company_size="Small"
                company_website_link="https://www.google.com"
                company_details_link="https://www.gagan257.tech"
                information={info[6]}
              />
              <ListCard
                Img={Img8}
                project_size="N/A"
                projects_done="100-499"
                company_size="Small"
                company_website_link="https://www.google.com"
                company_details_link="https://www.gagan257.tech"
                information={info[7]}
              />
              <ListCard
                Img={Img9}
                project_size="N/A"
                projects_done="100-499"
                company_size="Small"
                company_website_link="https://www.google.com"
                company_details_link="https://www.gagan257.tech"
                information={info[8]}
              />
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-12">
            <div class="row row-cols-1 row-cols-md-3 g-4">
              <ListCard
                Img={Img10}
                project_size="N/A"
                projects_done="100-499"
                company_size="Small"
                company_website_link="https://www.google.com"
                company_details_link="https://www.gagan257.tech"
              />
              <ListCard
                Img={Img11}
                project_size="N/A"
                projects_done="100-499"
                company_size="Small"
                company_website_link="https://www.google.com"
                company_details_link="https://www.gagan257.tech"
              />
              <ListCard
                Img={Img12}
                project_size="N/A"
                projects_done="100-499"
                company_size="Small"
                company_website_link="https://www.google.com"
                company_details_link="https://www.gagan257.tech"
              />
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-12">
            <div class="row row-cols-1 row-cols-md-3 g-4">
              <ListCard
                Img={Img13}
                project_size="N/A"
                projects_done="100-499"
                company_size="Small"
                company_website_link="https://www.google.com"
                company_details_link="https://www.gagan257.tech"
              />
              <ListCard
                Img={Img14}
                project_size="N/A"
                projects_done="100-499"
                company_size="Small"
                company_website_link="https://www.google.com"
                company_details_link="https://www.gagan257.tech"
              />
              <ListCard
                Img={Img15}
                project_size="N/A"
                projects_done="100-499"
                company_size="Small"
                company_website_link="https://www.google.com"
                company_details_link="https://www.gagan257.tech"
              />
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-12">
            <div class="row row-cols-1 row-cols-md-3 g-4">
              <ListCard
                Img={Img16}
                project_size="N/A"
                projects_done="100-499"
                company_size="Small"
                company_website_link="https://www.google.com"
                company_details_link="https://www.gagan257.tech"
              />
              <ListCard
                Img={Img17}
                project_size="N/A"
                projects_done="100-499"
                company_size="Small"
                company_website_link="https://www.google.com"
                company_details_link="https://www.gagan257.tech"
              />
              <ListCard
                Img={Img18}
                project_size="N/A"
                projects_done="100-499"
                company_size="Small"
                company_website_link="https://www.google.com"
                company_details_link="https://www.gagan257.tech"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
