import React, { useEffect } from "react";
import Image from "../../Images/form-img-1.png";

export default function Main(props) {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "//embed.typeform.com/next/embed.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <>
      <div className="container pt-5">
        <div className="row share-your-project-idea-text-container">
          <div className="col-md-4">
            <img
              src={Image}
              alt="Form_Image"
              className="pt-4"
              height={350}
              width={350}
            />
          </div>
          <div className="col-md-8">
            <div>
              <h1 className="ProjectForm_head">Share Your Project Idea</h1>
              <p className="ProjectForm_subhead">
                Turn Your Vision into Reality!
              </p>
            </div>
            <p className="share-your-project-idea-text mb-5 mt-5">
              Share your project idea with us, and our expert team at Bharat
              Soft Suppliers will get back to you promptly. Let's collaborate to
              create innovative and tailored software solutions that meet your
              unique business needs.
            </p>
            <span
              data-tf-live={props.formId}
              className="pt-3 pb-3 share-your-project-idea-form-btn"
            ></span>
          </div>
        </div>
      </div>
    </>
  );
}
