import React from "react";
import { Link } from "react-router-dom";

export default function ListCard(props) {
  const {information}=props;
  console.log(props);
  return (
    <>
      <div className="col">
        <div className="card h-100">
          <img
            src={props.Img}
            className="card-img-top"
            alt="Company_Logo_Image"
            height={200}
          />
          <div className="card-body">
            {/* <h2 className="card-title text-center mb-4">{props.title}</h2> */}
            <p className="card-text text-center">
              <b>Company Age:</b> {information?information.companyAge:props.project_size}
              <br />
              <b>Projects Done:</b> {information?information.projectCompleted:props.projects_done}
              <br />
              <b>Company Size:</b> {information?information.companySize:props.company_size}
            </p>
            <div className="text-center">
              <Link
                to={information?information.link:props.company_website_link}
                className="btn"
                style={{ backgroundColor: "#06038D", color: "white" }}
              >
                Company Website
              </Link>
              <br />
              <Link
                to={information?`/details/${information.id}`:'https://google.com'}
                className="btn mt-2"
                style={{ backgroundColor: "#06038D", color: "white" }}
              >
                Company Details
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
