import React from "react";
import BannerImg1 from "../../Images/banner-img1.png";
import BannerImg2 from "../../Images/banner-img2.png";
import BannerImg3 from "../../Images/banner-img3.png";
import { Link } from "react-router-dom";
// import BannerImg4 from "../../Images/banner-img4.png";

export default function Banner() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="container-fluid banner-main-div">
      <div
        id="carouselExampleAutoplaying"
        className="carousel slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          <div className="carousel-item active">
            <div className="container">
              <div className="row banner-text-row">
                <div className="col-md-7 mt-5 mb-5">
                  <h1 className="banner-text-heading mb-3">
                    Transform Your Business with Customized IT Solutions
                  </h1>
                  <p className="banner-text-p pt-2">
                    Elevate your operations with our bespoke software services.
                    Get started today and see the difference!
                  </p>
                  <div className="row mt-5">
                    <div className="col-md-4">
                      <Link
                        to="/company-register"
                        className="btn banner-button btn-lg"
                        onClick={scrollToTop}
                      >
                        Contact Us Now
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-5 mt-5 mb-5 d-flex justify-content-center">
                  <img src={BannerImg1} alt="" height={350} width={350} />
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="container">
              <div className="row banner-text-row">
                <div className="col-md-8 mt-5 mb-5">
                  <h1 className="banner-text-heading mb-3">
                    Experience Excellence in Software Development
                  </h1>
                  <p className="banner-text-p pt-2">
                    Partner with us for reliable, scalable, and secure IT
                    solutions tailored to your needs.
                  </p>
                  <div className="row mt-5 ">
                    <div className="col-md-4">
                      <Link
                        to="/company-map"
                        className="btn banner-button btn-lg"
                        onClick={scrollToTop}
                      >
                        Discover More
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mt-5 mb-5 d-flex justify-content-center">
                  <img
                    src={BannerImg2}
                    alt="Banner_Image"
                    height={350}
                    width={350}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="container">
              <div className="row banner-text-row">
                <div className="col-md-8 mt-5 mb-5">
                  <h1 className="banner-text-heading mb-3">
                    Stay Ahead with Cutting-Edge Technology
                  </h1>
                  <p className="banner-text-p pt-2">
                    Empower your business with innovative software solutions
                    designed for success. Let's innovate together!
                  </p>
                  <div className="row mt-5 ">
                    <div className="col-md-6">
                      <Link
                        to="/company-register"
                        className="btn banner-button btn-lg"
                      >
                        Get a Free Consultation
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mt-5 mb-5 d-flex justify-content-center">
                  <img
                    src={BannerImg3}
                    alt="Banner_Image"
                    height={350}
                    width={350}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
