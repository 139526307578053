import React from "react";
import Banner from "../Banner/banner";
import WhyUS from "../WhyUs/main";
import ProjectForm from "../ProjectForm/main";
import AddCompany from "../AddCompany/main";
import Blogs from "../Blogs/frontMain";
import { Link } from "react-router-dom";

export default function main() {
  return (
    <div>
      <Banner />
      <ProjectForm formId="01HN157YRV2NV809SSM5DDRBSX" />
      <WhyUS />
      <AddCompany />
      {/* <Blogs /> */}
    </div>
  );
}
