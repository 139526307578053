import "./App.css";
import LandingPage from "./components/LandingPage/main";
import Footer from "./components/Footer/footer";
import Navbar from "./components/Navbar/Navbar";
import About from "./components/About/main";
import RegistrationPage from "./components/CompanyRegistration/main";
import CompanyMapPage from "./components/CompanyMap/main";
import DetailsPage from "./components/CompanyMap/Details";
import NotFound from "./components/Not-Found/main";

import TopLoader from "nextjs-toploader";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App() {
  return (
    <>
      <Router>
        <TopLoader color="#05048A" showSpinner={false} height={4} />
        <Navbar />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/company-map" element={<CompanyMapPage />} />
          <Route path="/details/:id" element={<DetailsPage/>}/>
          <Route path="/blogs" element={<LandingPage />} />
          <Route path="/about" element={<About />} />
          <Route
            path="/company-register"
            element={<RegistrationPage formId="01HS6P66B2M61M02DRQJP0BWH7" />}
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
