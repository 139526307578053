import React from "react";
import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <p className="zoom-area">Page you are looking for does not exists!</p>
      <section className="error-container">
        <span className="four">
          <span className="screen-reader-text">4</span>
        </span>
        <span className="zero">
          <span className="screen-reader-text">0</span>
        </span>
        <span className="four">
          <span className="screen-reader-text">4</span>
        </span>
      </section>
      <div className="link-container pb-5 mb-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <Link to={"/"} className="more-link" rel="noreferrer">
                Back to Home
              </Link>
            </div>
            <div className="col-md-6">
              <Link
                to={"/company-register"}
                className="more-link"
                rel="noreferrer"
              >
                Company Registration
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
